<template>
  <modal
    transition="nice-modal-fade"
    width="680"
    :adaptive="true"
    :reset="true"
    height="auto"
    :name="modalName"
  >
    <div class="modal--wrapper">
      <div class="back--wrapper" v-if="$mq === 'xs'">
        <div class="back--button" @click="close()">
          <img src="@/assets/img/icons/back-2.svg" />
        </div>
        {{ $t('booking.modal.acceptTour.title') }}
      </div>
      <section class="modal--container accept--survey-container">
        <div class="desc">
          {{ $t('booking.modal.acceptTour.message') }}
        </div>
        <div class="date--time">
          <div class="single--row">
            <div class="left--col">{{ $t('booking.modal.initTour.date') }}</div>
            <div class="right--col">15 Feb 2021</div>
          </div>
          <div class="single--row">
            <div class="left--col">{{ $t('general.selectHour') }}</div>
            <div class="right--col">10:00</div>
          </div>
        </div>
        <hr class="separator" />
        <div class="d-flex justify-space-between" :class="{ 'flex-wrap': $mq === 'xs' }">
          <div class="btn btn-primary--outline main--btn" @click="close">
            {{ $t('general.btn.back') }}
          </div>
          <div class="btn btn-primary main--btn" @click="close">
            {{ $t('booking.modal.acceptTour.acceptBtn') }}
          </div>
        </div>
      </section>
      <button type="button" class="close" @click="close" v-if="$mq !== 'xs'">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
    </div>
  </modal>
</template>

<script>
export default {
  data: () => ({
    modalName: 'modal-accept-survey-confirm',
  }),
  methods: {
    close() {
      this.$modal.hide(this.modalName);
    },
  },
};
</script>
